<template>
  <div>
    <div class="top_div" @click="goBack">
      <i class="el-icon-arrow-left left_link"></i>测评
    </div>

    <div class="card" v-loading="saveLoading">
      <div class="tiwenji">
        <el-slider
          v-model="value"
          class="slide"
          height="700px"
          :max="10"
          :min="0"
          :step="1"
          vertical
        />
      </div>
      <div class="save_btn" @click="saveResult">提交</div>
    </div>
  </div>
</template>

<script>
import { create } from "@/api/wenjuanResult";
export default {
  name: "VasScore",
  data() {
    return {
      uid: null,
      type: null,
      wenjuan_id: null,
      value: 0,
      saveLoading: false, //保存答案中
    };
  },
  mounted() {
    //没有uid就进去选择病人
    if (this.$route.query.uid) {
      this.uid = this.$route.query.uid;
    } else {
      this.go_choose_patient();
    }
    //没有问卷id就去选择对应的问卷
    if (this.$route.query.wenjuan_id) {
      this.wenjuan_id = this.$route.query.wenjuan_id;
    } else {
      this.go_index();
    }
    if (this.$route.query.type) {
      this.type = this.$route.query.type;
    }
  },
  methods: {
    //跳转至选择病人页面
    go_choose_patient() {
      this.$router.push({
        path: "choose_patient",
      });
    },
    //点击顶部的返回按钮
    goBack() {
      let that = this;
      this.$confirm("返回后需要重新做题，确定要返回吗?", "提示", {
        confirmButtonText: "确定返回",
        cancelButtonText: "再想一想",
        type: "warning",
        buttonSize: "large",
      })
        .then(() => {
          that.go_index();
        })
        .catch(() => {});
    },
    //跳转至选择问卷页面
    go_index() {
      this.$router.push({
        path: "/",
        query: { uid: this.uid },
      });
    },
    //保存答题结果，并跳转页面
    async saveResult() {
      if (this.saveLoading) {
        return;
      }
      this.saveLoading = true;
      let saveData = {
        wenjuan_id: this.wenjuan_id,
        wenjuan_identify: this.type,
        patient_id: this.uid,
        ans_detail: [this.value],
      };
      try {
        const { data } = await create(saveData);
        await this.$router.push({
          path: "report_create",
          query: { id: data.id },
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.saveLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.top_div {
  display: flex;
  align-items: center;
  width: 100%;
  height: 131px;
  font-size: 48px;
  font-weight: bold;
  color: #101010;
  background-color: #fff;
}
.left_link {
  margin-left: 15px;
}
.card {
  width: 676px;
  padding: 72px 58px;
  margin: 0px auto;
  margin-top: 89px;
  margin-bottom: 40px;
  font-size: 42px;
  background: #ffffff;
  border-radius: 50px;
  box-shadow: 0px 0px 18px 0px rgba(7, 133, 153, 0.06);
}
.tiwenji {
  background-image: url("../../assets/vas.png");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
}
.slide {
  padding-top: 50px;
  padding-bottom: 110px;
  margin-left: 153px;
}
.ans_title {
  margin-bottom: 92px;
}
.ans_item {
  width: 641px;
  height: 102px;
  margin: 0px auto;
  margin-top: 36px;
  font-size: 36px;
  font-weight: bold;
  line-height: 102px;
  color: #606266;
  text-align: center;
  cursor: pointer;
  background: #f2f2f2;
  border-radius: 51px;
}
.ans_item_choose {
  color: #fff;
  background-color: #078599;
}
.save_btn {
  width: 420px;
  height: 102px;
  margin: 0px auto;
  margin-top: 140px;
  font-size: 36px;
  font-weight: 400;
  line-height: 102px;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
  background: #078599;
  border-radius: 51px;
}
</style>
